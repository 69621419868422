import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import styled, { createGlobalStyle } from "styled-components"
import SEO from "../components/seo";
import Section from "../components/Section"
import Container from "../components/container"
import Col from "../components/Col"
import Row from "../components/Row"
import ExpandingCard from "../components/ExpandingCard"
import CardModal from "../components/CardModal"


const PageStyle = createGlobalStyle`
  #hero-section{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #service-background{
    padding:40px 0;
    background-color: #F5F5F7;
  }
  #design-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:28px;

    #col-1{
      @media (max-width:980px)  { 
      grid-column: 1/3;
      order: 2;
  }
    }
    #col-2{
      @media (max-width:980px)  { 
      grid-column: 1/3;
      order: 1;
    }
      background-color: blue;
      order: 2;
    }
    #col-3{
      background-color: green;
      order: 3;
      grid-column: 1/3;
      height:50vh;
    }
  }

  #service-section{

    @media (max-width:980px)  {
      grid-template-rows: repeat(7,150px); 
      grid-template-areas:
              "a a"
              "b b"
              "c c"
              "d e"
              "f g"
              "h i"
              "j k";
      gap:14px;
    }

      /* background-color: grey; */
      margin-top: 28px;
      margin-bottom: 28px;
      display: grid;
      grid-template-rows: repeat(6,300px);
      grid-template-areas:
              "a a b"
              "a a b"
              "c d e"
              "f f e"
              "g h i"
              "j k k";
      grid-auto-flow: row;
      gap: 28px;
      

      #cad-modeling-card{
        grid-area: a;
        
        h2{
          font-size: 5vw;
          /* text-align: left; */
        }
      }

      #industrial-design-card{
        grid-area: b;
      }

      #concept-design-card{
        grid-area: c;
      }

      #form-design-card{
        grid-area: d;
      }

      #rendering-card{
        grid-area: e;
      }

      #animation-card{
        grid-area: f;
      }

      #graphic-design-card{
        grid-area: g;
      }

      #web-design-card{
        grid-area: h;
      }

      #app-design-card{
        grid-area: i;
      }

      #branding-card{
        grid-area: j;
      }
      
      #ux-ui-card{
        grid-area: k;
      }

      


  }
`
const Hero = styled.div`
  background-color: black;
  height: 80vh;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;

  h1{
    color:white;
    font-size:20vw;
  }
`

const Intro = styled.div`
  margin: auto;
  height: 40vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 40px auto 80px auto;

  h2{
    font-size: 40px;
    /* padding: 40px 0 20px 0; */
  }

  h3{
    font-size: 26px;
    /* padding: 20px 0 40px 0; */
  }

  #intro-button{
    margin-bottom: 40px;
  }
`

const EyeBrow = styled.h4`
  font-size: 21px;
  text-transform: uppercase;
`

const Description =styled.h2`
  font-size: 40px;
  color: #888;
  font-weight: 600;
  span{
    font-weight: 600;
    color: black;
  }

`
  


const DesignPage = () => {

  return (
    <div>
    <Layout />
    <PageStyle />
    <SEO title="Design"/>
    <Section id="hero-section">
      <Hero>
        <h1>Design</h1>
      </Hero>
    </Section>
    <Section xl>
    <Intro className="md">
      <h2 className="xs">Every great product starts with a great design.</h2>
      <h3 className="sm">It isn’t enough to produce a product that merely functions. At Steele, form and function complement one another. Here, imagination and engineering requirements merge together into a single, coherent solution. A product designed to succeed right from the start.</h3>
      <Link id="intro-button" className="button solid-dark center" to="/contact">List of Our Services</Link>
    </Intro>
    </Section>
    <Section lg id="design-section">
      {/* <Row> */}
      <Col id="col-1" dt={50} tb={100} mb={100}>
        <EyeBrow>
          section title And theme
        </EyeBrow>
        <Description>
        <span> Designed to grab attention. But subtly.</span>
         Rest of text follows in a lighter color. This is the body text. Use this area to expand on the catchy line above. Explain a pillar or design or engineering for instance. Some principle or overarching theme.
        </Description>
        </Col>
      <Col id="col-2" dt={50} tb={100} mb={100}>
        <StaticImage src="https://via.placeholder.com/700"/>
        </Col>
        {/* </Row> */}
        {/* <Row> */}
      <Col id="col-3">
    </Col>
      {/* </Row> */}
    </Section>
    <div id="service-background">
    <Section id="service-section" lg>
      <CardModal id="cad-modeling-card" title="CAD Modeling" backgroundImage="https://i.pinimg.com/564x/a2/80/21/a2802130e92422479df3dc091eb705de.jpg">
      {/* <StaticImage src="https://via.placeholder.com/800"/> */}
      <p>At its core, CAD modeling is the art of creating virtual designs on a computer. And like all great technology, CAD modeling has the power to revolutionize the way we create, build, and innovate. By utilizing CAD modeling, customers can bring their ideas to life in ways they never thought possible. With precision, accuracy, and speed, CAD modeling can transform the customer's vision into a stunning reality, all while saving time, money, and resources. At Apple, we believe that CAD modeling is the key to unlocking limitless possibilities, and we're proud to be at the forefront of this groundbreaking technology.
      At its core, CAD modeling is the art of creating virtual designs on a computer. And like all great technology, CAD modeling has the power to revolutionize the way we create, build, and innovate. By utilizing CAD modeling, customers can bring their ideas to life in ways they never thought possible. With precision, accuracy, and speed, CAD modeling can transform the customer's vision into a stunning reality, all while saving time, money, and resources. At Apple, we believe that CAD modeling is the key to unlocking limitless possibilities, and we're proud to be at the forefront of this groundbreaking technology.
      </p>
        </CardModal>
        <CardModal id="industrial-design-card" title="Industrial Design" backgroundImage="https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/98c04559215137.5a19e56e3ba51.png">
        </CardModal>
        <CardModal id="concept-design-card" title="Concept Design" backgroundImage="https://mir-s3-cdn-cf.behance.net/projects/404/7520c6103920685.Y3JvcCwxMDc4LDg0MywwLDExNw.png">
        </CardModal>
        <CardModal id="form-design-card" title="Form Design" backgroundImage="https://miro.medium.com/v2/resize:fit:1400/1*zXW4ICe8ywbjbftloVt-cQ.png">
        </CardModal>
        <CardModal id="rendering-card" title="Rendering" backgroundImage="https://mir-s3-cdn-cf.behance.net/projects/404/7520c6103920685.Y3JvcCwxMDc4LDg0MywwLDExNw.png">
        </CardModal>
        <CardModal id="animation-card" title="Animation" backgroundImage="https://i.pinimg.com/originals/00/bf/16/00bf16e0b2326fe28d5373bc58f1ada9.gif">
        </CardModal>
        <CardModal id="graphic-design-card" title="Graphic Design" backgroundImage="https://i.pinimg.com/564x/23/f2/2f/23f22f24b3ec25d32966116cd2776559.jpg">
        </CardModal>
        <CardModal id="web-design-card" title="Web Design" backgroundImage="https://cdn.dribbble.com/users/61848/screenshots/5025108/dbshot.png">
        </CardModal>
        <CardModal id="app-design-card" title="App Design" backgroundImage="https://cdn.dribbble.com/users/737304/screenshots/14473728/media/c9c0748f69bcd4b66b3cadce39700a67.png">
        </CardModal>
        <CardModal id="branding-card" title="Branding" backgroundImage="https://i.pinimg.com/564x/10/5c/10/105c100c907a1850c8005d675affab42.jpg">
        </CardModal>
        <CardModal id="ux-ui-card" title="UX/UI" backgroundImage="https://cdn.dribbble.com/userupload/3245730/file/original-c5a57f02c39bb3e1353b584dfc775ff0.png?compress=1&resize=1504x1128">
        </CardModal>
    </Section>
    </div>

    </div>
  )
}

export default DesignPage
