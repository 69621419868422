import React from 'react';
import styled from 'styled-components';

const ColWrapper = styled.div`
  flex-basis: ${props => props.width ? props.width : 'auto'};
  flex-grow: ${props => props.width ? 0 : 1};
  max-width: 100%;
  box-sizing: border-box;
  
  @media (min-width: 980px) {
    flex-basis: ${props => props.dt ? `${props.dt}%` : 'auto'};
  }
  
  @media (max-width: 979px) {
    flex-basis: ${props => props.tb ? `${props.tb}%` : 'auto'};
  }
  
  @media (max-width: 680px) {
    flex-basis: ${props => props.mb ? `${props.mb}%` : 'auto'};
  }
`;

const Col = ({ dt, tb, mb, id, children }) => {
  return (
    <ColWrapper id={id} dt={dt} tb={tb} mb={mb}>
      {children}
    </ColWrapper>
  );
};

export default Col;
