import React, { useState, useRef, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const Card = styled.div`
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
    background-image: url(${props => props.backgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width:480px)  {
        padding: 10px;
  }

`

const Button = styled.button`
  width: 40px;
  height: 40px;
  margin: -20px;
  border-radius: 50%;
  border: 2.5px solid;
  border-color: ${({ showModal }) => (showModal ? "black" : "white")};
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
  align-self: flex-end;

  @media (max-width:480px)  {
    bottom: 14px;
    right: 14px;
  }
  
`;

const VerticalLine = styled.div`
  width: 20px;
  height: 2.5px;
  background: ${({ showModal }) => (showModal ? "black" : "white")};
  position: absolute;
`;

const HorizontalLine = styled(VerticalLine)`
  transform: rotate(90deg);
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`


const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    z-index: 1;
`;

const ModalContent = styled.div`
    background-color: white;
    border-radius: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 980px;
    height: 80vh;
    overflow-y: auto;
    align-items: center;
    display: flex;
    flex-direction: column;

    #close-button{
    grid-column: 3;
    margin-left: auto;
    position: sticky;
    margin: 8px 8px 0 auto;
    }
`

const ModalImage = styled.div`
    width: 100%;
    height: 600px;
    background-image: url(${props => props.backgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: red;
`

const ModalHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    width: 100%;
    max-width: 980px;
    background-color: white;
    position: fixed;
    z-index: 10;
    border-radius: 14px 14px 0 0;
    margin-bottom: 14px;
`

const ModalTitle = styled.h2`
    text-align: left;
    grid-column: 2;
    margin: auto;
    color: ${({ showModal }) => (showModal ? "black" : "white")};

`

const ModalInner = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: calc(100% - 105px);
    max-width: 680px;
`

const GlobalStyles = createGlobalStyle`
  body {
    overflow: ${({ showModal }) => (showModal ? "hidden" : "auto")};
  }
`;

const CardModal = ({ children, id, title, backgroundImage }) => {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Card backgroundImage={backgroundImage} id={id}>
        {showModal ? (
          <ModalTitle>{title}</ModalTitle>
        ) : (
            <ModalTitle style={{ fontSize: "5vw" }}>{title}</ModalTitle>
        //   <h2>{title}</h2>
        )}
        <Button onClick={toggleModal}>
            <VerticalLine />
            <HorizontalLine />
        </Button>
      </Card>
      {showModal && (
        <>
          <ModalBackground>
            <ModalContent ref={modalRef}> 
                <ModalHeader>
                <ModalTitle showModal={true}>{title}</ModalTitle>
                
                <Button id="close-button" onClick={toggleModal} showModal={true}>
                    <VerticalLine showModal={true} style={{ transform: 'rotate(45deg)' }} />
                    <HorizontalLine showModal={true} style={{ transform: 'rotate(-45deg)' }} />
                </Button>
                </ModalHeader>
                <ModalInner>
                <ModalImage backgroundImage={backgroundImage} />
                    {children}
                </ModalInner>
            </ModalContent>
          </ModalBackground>
          <GlobalStyles showModal={showModal} />
        </>
      )}
    </>
  );
};

export default CardModal;
