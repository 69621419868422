import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const BackDrop = styled.div`
  background-color: red;
  z-index:998;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const Box = styled.div`
  background-color: black;
  color: white;
  border: 4px solid #333;
  border-radius: 24px;
  width: 100%;
  max-width: ${({ active }) => (active ? '1280px' : '')};
  height: ${({ active }) => (active ? '80vh' : '')};
  position: ${({ active }) => (active ? 'fixed' : 'static')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: ${({ active }) => (active ? 'auto' : 'none')};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  z-index: ${({ active }) => (active ? '999' : '1')};
  overflow: scroll;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
`;

const Button = styled.button`
  background-color: transparent;
  margin-left: auto;
  margin-right: 6px;
  margin-top: auto;
  margin-bottom: 6px;
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  color: white;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  position: sticky;
  top: .1em;
  right: .1em;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`

const ActiveTitle = styled(Title)`
  margin-top: 16px;
`;

const ActiveText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-top: 16px;
  max-width: 500px;
  padding: 0 14px 0 14px;
`;

const ExampleComponent = ({ title, imageSource, children }) => {
  const [active, setActive] = useState(false);

  const handleButtonClick = () => {
    setActive(true);
  };

  const handleCloseClick = () => {
    setActive(false);
  };

  const handleBoxClick = e => {
    if (active && e.target === e.currentTarget) {
      setActive(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [active]);

  return (
    
    <Box active={active} onClick={handleBoxClick}>
      {!active && <Title>{title}</Title>}
      {active && (
        <CloseButton onClick={handleCloseClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="73.634" height="73.634" viewBox="0 0 73.634 73.634">
          <g id="Icon_feather-plus-circle" data-name="Icon feather-plus-circle" transform="translate(-1 -1)">
            <path id="Path_11" data-name="Path 11" d="M72.634,37.817A34.817,34.817,0,1,1,37.817,3,34.817,34.817,0,0,1,72.634,37.817Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
            <path id="Path_12" data-name="Path 12" d="M18,12V39.853" transform="translate(43.422 6.756) rotate(45)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
            <path id="Path_13" data-name="Path 13" d="M12,18H39.853" transform="translate(32.211 6.756) rotate(45)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
          </g>
        </svg>
        </CloseButton>
      )}
      {!active && (
        <Button onClick={handleButtonClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="73.634" height="73.633" viewBox="0 0 73.634 73.633">
          <g id="Icon_feather-plus-circle" data-name="Icon feather-plus-circle" transform="translate(2 2)">
            <path id="Path_11" data-name="Path 11" d="M72.634,37.817A34.817,34.817,0,1,1,37.817,3,34.817,34.817,0,0,1,72.634,37.817Z" transform="translate(-3 -3)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
            <path id="Path_12" data-name="Path 12" d="M18,12V39.853" transform="translate(16.817 8.89)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
            <path id="Path_13" data-name="Path 13" d="M12,18H39.853" transform="translate(8.89 16.817)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
          </g>
        </svg>
        </Button>
      )}
      {active && (
        <>
          <Image src={imageSource} />
          <ActiveTitle>{title}</ActiveTitle>
          <ActiveText>{children}</ActiveText>
        </>
      )}
      
    </Box>
  );
};

export default ExampleComponent;
